  body {
  	margin: 0;
  	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  		sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  }

  code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  		monospace;
  }

  .react-select__input:focus {
  	box-shadow: none;
  }

  .select-wrapper {
  	position: relative;
  }

  .select-wrapper label {
  	position: absolute;
  	top: 10px;
  	left: 25px;
  	font-size: 11px;
  	background-color: white;
  	color: #6B7280;
  	z-index: 50;
  	transition: transform 0.2s ease-out;
  }

  .select-wrapper .react-select-control {
  	padding-top: 25px;
  }

  .select-wrapper .react-select-control:focus-within label {
  	transform: translateY(-15px);
  	font-size: 14px;
  }

  .flatpickr-calendar.open {
  	z-index: 20 !important;
  }


  /* 
  medications {
  	margin-top: '25px';
  	margin-bottom: '-30px';
  	margin-left: '19px';
  	color: '#6B7280';
  	position: 'relative';
  	top: '10px';
  	z-index: 20;
  	width: '65px';
  	padding-left: '3px';
  	background: 'white'
  } */

  .signature {
  	display: block;
  	margin: 100px auto;
  	border: 2px solid red;
  	background-color: white;
  	width: 500px;
  	height: 200px;
  }

  .flatpickr-calendar.open {
    z-index: 99999999 !important;
}