.btnnn {
	cursor: pointer;
}


input#start-date {
	position: relative;
	overflow: hidden;
}

input#start-date::-webkit-calendar-picker-indicator {
	display: block;
	top: 0;
	left: 0;
	background: #0000;
	position: absolute;
	transform: scale(50)
}

input#end-date {
	position: relative;
	overflow: hidden;
}

input#end-date::-webkit-calendar-picker-indicator {
	display: block;
	top: 0;
	left: 0;
	background: #0000;
	position: absolute;
	transform: scale(50)
}

.close-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 23px;
	border-radius: 50%;
	text-align: center;
	color: white;
	background-color: #3A52BB;
	cursor: pointer;
	display: none;
}

#start-date:focus+.close-icon {
	display: block;
}

#end-date:focus+.close-icon {
	display: block;
}

body {
	background: #f4f2ee;
	/* background: #f0f1f3; */
}

/* CSS */
.scrollable-container {
	width: 100%;
	/* Set your desired width */
	height: 200px;
	/* Set your desired height */
	overflow: hidden;
	/* Hide the scrollbar */
	position: relative;
	/* Ensure the container has a position */
}

.scrollable-image {
	width: 1124px;
	height: 200px;
}

.pac-logo .pac-item {
	padding: 10px 20px;
	cursor: pointer;
}


.pac-logo:after {
	display: none;
}

.pac-logo {
	margin-top: 10px;
	/* width: 25% !important; */
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(112, 144, 176, 0.2);
	z-index: 9999 !important;
}



.error-container {
	position: relative;
	overflow: hidden;
	transition: height 0.3s ease-in-out;
}

.error-icon {
	font-size: 24px;
	line-height: 1;
}

.error-container:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transform: translateY(-2px);
}

.EmojiPickerReact .Flex.FlexRow {
	display: none;
}

.profile-activity-indicator {
	right: -6px;
	bottom: -4px;
}



.modal.ot_imgGallery {
	display: none;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.8);
}

.ot_imgGallery .modal-content {
	position: relative;
	padding: 20px;
	width: 80%;
	max-width: 900px;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

/* 
.slick-slide img {
	width: auto;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
} */

.slick-slider {
	position: relative;
}

.slick-slide {
	position: relative;
}

.slick-slide img {
	width: auto;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.close {
	position: absolute;
	top: 20px;
	right: 20px;
	color: #000;
	cursor: pointer;
	font-size: 50px;
	z-index: 9;
	background: #FFF;
	line-height: 29px;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	text-align: center;
	font-size: 40px;
}

.navigation-buttons {
	text-align: center;
	margin-top: 20px;
}

.slick-prev,
.slick-next {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 1);
	color: #000;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1000;
}

.slick-prev {
	left: -10%;
}

.slick-next {
	right: -10%;
}

.slick-initialized .slick-slide {
	display: flex;
	align-items: center;
	vertical-align: middle;
	height: 100vh;
}

.epr-body::-webkit-scrollbar,
.epr-bod::-webkit-scrollbar-thumb {
	border-radius: 4px;
	overflow: visible;
}

.epr-body::-webkit-scrollbar {
	width: 0.4em;
}

.epr-body::-webkit-scrollbar-thumb {
	background: #0003;
}

.epr-body::-webkit-scrollbar,
.epr-bod::-webkit-scrollbar-thumb {
	border-radius: 4px;
	overflow: visible;
}

.chat-body.scroll-box .rubik-400.text-center {
	position: relative;
	top: 10px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 1;
	background: rgb(0 0 0 / 8%);
	width: max-content;
	display: inline-block;
	padding: 2px 15px 5px 15px;
	border-radius: 5px;
	color: #3a52bb;
	height: 19px;
	width: 95px;
	font-size: 10px;
	transition: top 0.3s ease-in-out;
	margin-bottom: 15px;
}

.hide-on-scroll {
	position: fixed;
	top: -50px;
	right: 20px;
	background-color: #333;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	z-index: 999;
	transition: top 0.3s ease-in-out;
}

.visible {
	top: 10px;
}

/* Example CSS */
.sticky-date {
	position: fixed;
	top: 0;
	width: 100%;
	height: 1000px;
	background-color: #fff;
	padding: 8px;
	border-bottom: 1px solid #ccc;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
	/* Initially hidden */
}

.sticky-date.show {
	opacity: 1;
	/* Show when scrolling down */
}

.sticky-date.hide {
	opacity: 0;
}

.ql-toolbar.ql-snow {
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;

}

.borderLeftBg {
	border: 1px solid var(--blueColor);
	border-left: 7px solid var(--blueColor);
}

.lightBgColor {
	background-color: #ebebeb;
}

.borderLeftBgGreen {
	border: 1px solid var(--greenColor);
	border-left: 7px solid var(--greenColor);
}

.listActive .active {
	background-color: #fff;
	color: #000 !important;
}

.sidebarListActive .active {
	background-color: var(--blueColor);
	/* background-color: #a3abd0; */
	/* color: #fff; */
	color: #fff;
	padding: 10px;
}

.ot_takePictureBgSidebar {
	background-color: rgb(0 0 0 / 40%);
	padding: 5px;
	position: absolute;
	width: 63px;
	left: 1px;
	right: 0;
	bottom: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.react-select__indicator-separator.css-1u9des2-indicatorSeparator {
	display: none;
}

.css-1mxjys5-control:hover,
.css-1mxjys5-control {
	border-color: transparent !important;
	box-shadow: none !important;
}

.css-9ld5kd-control:hover {
	border-color: transparent !important;
}

.css-9ld5kd-control {
	position: absolute;
}

.tooltipBefore {
	position: absolute;
}

.tooltipBefore:before {
	content: '';
	position: absolute;
	top: -11px;
	left: 35px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 12px solid rgb(170 180 225 / 72%);
}

.tooltipBefore_1 {
	position: relative;
}

.tooltipBefore_1:before {
	content: '';
	position: relative;
	left: 35px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 12px solid rgb(170 180 225 / 72%);
}


.Oat_HeaderSeachBg .react-select__indicator.react-select__dropdown-indicator:after {
	content: '';
	background: #999999;
	width: 1px;
	height: 25px;
	display: block;
	position: absolute;
	right: 0;
	top: 10px;
}

.react-select__control.css-9ld5kd-control {
	width: 120px;
}

.allNotificationSearchBg {
	align-items: center;
	grid-template-columns: 1fr 1fr 7px 1fr 1fr;
}

@media(max-width: 768px) {
	.copyPastBg #canvimg {
		width: 100%;
	}
}


@media(max-width: 576px) {
	.pac-logo {
		width: 51% !important;
		left: 45% !important;
	}
}

@media(max-width: 1200px) {
	.ot_headerList {
		overflow: scroll;
		width: 100%;
		word-break: keep-all;
		white-space: nowrap;
		grid-gap: 10px;
	}
}

/* FileManager.css */

.file-manager-container {
	max-width: 600px;
	margin: 0 auto;
}

.create-folder {
	margin-bottom: 20px;
}

.create-folder input[type="text"] {
	padding: 8px;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 200px;
}

.create-folder button {
	padding: 8px 12px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.create-folder button:hover {
	background-color: #0056b3;
}

.folder-list {
	list-style-type: none;
	padding: 0;
}

.folder {
	width: 9rem;
	padding: 10px;
	border-radius: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.folder-icon {
	width: 32px;
	height: 32px;
	margin-right: 10px;
}

.folder-name {
	font-weight: bold;
	font-size: 16px;
	color: #333;
}

.custom-file {
	position: relative;
	display: flex;
	width: 100%;
	height: calc(2.125rem + 2px);
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(2.125rem + 2px);
	margin: 0;
	opacity: 0;
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(2.125rem + 2px);
	padding: 0.4375rem 1rem;
	background-color: #fff;
	border: 1px solid #dbdfea;
	border-radius: 4px;
}

.custom-file button {
	position: relative;
	z-index: 999;
	width: 7rem;
	background-color: beige;
}


.hover-trigger .hover-target {
	display: none;
}

.hover-trigger:hover .hover-target {
	display: block;
}


  
  .custom-datepicker {
	width: 100%;
  }
  
  .custom-datepicker input {
	width: 100%;
	padding: 10px;
	outline: none;
	border-radius: 8px; 
  }
  


  /* styles.css */
.container_abc {
	display: flex;
	justify-content: space-around;
	padding: 20px;
  }
  
  .todo-list {
	background: #f4f4f4;
	border-radius: 8px;
	padding: 4px;
	width: 300px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .todo-item {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 8px;
	padding: 10px;
	transition: background 0.3s;
  }
  
  .todo-item:hover {
	background: #f1f1f1;
  }
  
  .todo-item p {
	margin: 0;
	color: #333;
  }
  
  .todo-list-title {
	font-size: 1.2em;
	margin-bottom: 16px;
	text-align: center;
  }
  
  .droppable-area {
	padding: 8px;
	min-height: 200px;
  }
  