@import url('https://fonts.googleapis.com/css2?family=Onest:wght@200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

:root {
	--rubikFont: 'Rubik', sans-serif;
	--weight400: 400;
	--weight600: 600;
	--darkColor: #1B2336;
	--lightColor: #ffffff;
	--greyColor: #757575;
	--blueColor: #3A52BB;
	--purplrColor: #273A90;
	--blueLightBgColor: #596AAB;
	--greenColor: #34A853;
	--greenBgColor: #50B83F;
	--redColor: #FA3737;
	--redBgColor: #FA3737;
	--yellowBgColor: #FF9800;
	--redTblColor: #E14942;
	--lightBlueColor: #415674;
	--tableBgColor: #F8FAFB;
	--borderColor: #E5E5E5;
	--taskBoxBgColor: #F5F5F5;
	--editBtnColor: #D9D9D9;
	--lightRedColor: rgba(250, 55, 55, 0.1);
	--lightGreenColor: rgba(80, 184, 63, 0.2);
	--lightBorder: #DADADA;
}


.rubik {
	font-family: var(--rubikFont);
}

.rubik-400 {
	font-weight: 400;
}

.rubik-500 {
	font-family: var(--rubikFont);
	font-weight: 500;
}

.rubik-600 {
	font-family: var(--rubikFont);
	font-weight: 600;
}

.rubik-700 {
	font-family: var(--rubikFont);
	font-weight: 700;
}

.rubik-800 {
	font-family: var(--rubikFont);
	font-weight: 800;
}

.rubik-900 {
	font-family: var(--rubikFont);
	font-weight: 900;
}

/* scrolling */

.scroll-box_x {
	width: 100%;
	overflow-x: scroll;
}

.scroll-box_x::-webkit-scrollbar {
	height: .4em;
}

.scroll-box_x::-webkit-scrollbar,
.scroll-box_x::-webkit-scrollbar-thumb {
	overflow: visible;
	border-radius: 4px;
}

.scroll-box_x::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, .2);
}

.scroll-bar-wrap {
	position: relative;
}

.scroll-box::-webkit-scrollbar {
	width: .4em;
}

.scroll-box::-webkit-scrollbar,
.scroll-box::-webkit-scrollbar-thumb {
	overflow: visible;
	border-radius: 4px;
}

.scroll-box::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, .2);
}

.cover-bar {
	position: absolute;
	background: #fff;
	;
	height: 100%;
	top: 0;
	right: 0;
	width: .4em;
	-webkit-transition: all .5s;
	opacity: 1;
}

/* MAGIC HAPPENS HERE */
.scroll-bar-wrap:hover .cover-bar {
	opacity: 0;
	-webkit-transition: all .5s;
}




.liveNowBtn {
	background-color: #e6eaff;
}

.lightBgColorSignin {
	background-color: rgb(58 82 187 / 0.05);
}

.lightBorder {
	border-color: var(--lightBorder);
}

.bg_light {
	background-color: var(--lightColor);
}

.purpleBadge {
	background-color: rgba(39, 58, 144, 0.2);
	color: var(--purplrColor);
}

.redBadge {
	background-color: rgba(250, 55, 55, 0.1);
	color: var(--redColor);
}

.greenBadge {
	background-color: rgba(80, 184, 63, 0.2);
	color: var(--greenBgColor);
}

.editBtn {
	background-color: var(--editBtnColor);
}

.task_bgColor {
	background-color: var(--taskBoxBgColor);
}

.border_Color {
	border-color: var(--borderColor);
}

.borderColor {
	background-color: var(--borderColor);
}

.greyBorder-color {
	border-color: var(--greyColor) !important;
}

.grey-color {
	color: var(--greyColor);
}

.dark-color {
	color: var(--darkColor);
}

.light-color {
	color: var(--lightColor);
}

.bgLight-color {
	background-color: var(--lightColor);
}

.tableBg-color {
	background-color: var(--tableBgColor);
}

.ot_headerDropdown {
	border-radius: 2px 0 2px 2px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
	background-color: var(--lightColor);
	width: 255px;
	transform: none !important;
	inset: unset !important;
	right: -8px !important;
	top: 40px !important;
}

.ot_headerDropdown ul {
	background-color: var(--taskBoxBgColor);
}

.blueLightBg-color {
	background-color: var(--blueLightBgColor);
}

.blue-color {
	color: var(--blueColor);
}

.redTbl-color {
	color: var(--redTblColor);
}

.closeBtn {
	background-color: var(--redBgColor);
}

.blueBg-color {
	background-color: var(--blueColor);
}

.greenBg-color {
	background-color: var(--greenBgColor);
}

.yellowBg-color {
	background-color: var(--yellowBgColor);
}

.redBg-color {
	background-color: var(--redBgColor);
}

.leftArrow,
.rightArrow {
	background-color: rgba(248, 250, 251, 1);
}

.lightBlue-color {
	color: var(--lightBlueColor);
}

.lightRed-color {
	background-color: var(--lightRedColor);
}

.lightGreen-color {
	background-color: var(--lightGreenColor);
}

.green-color {
	color: var(--greenColor);
}

.red-color {
	color: var(--redColor);
}

.bg-light {
	background-color: #f9f9f9;
}

.fs-6 {
	font-size: 6px;
}

.fs-8 {
	font-size: 8px;
}

.fs-10 {
	font-size: 10px;
}

.fs-12 {
	font-size: 12px;
}

.fs-14 {
	font-size: 14px;
}

.fs-16 {
	font-size: 16px;
}

.fs-18 {
	font-size: 18px;
}

.fs-22 {
	font-size: 22px;
}

.fs-24 {
	font-size: 24px;
}

.fs-30 {
	font-size: 30px;
}

.fs-48 {
	font-size: 9em;
	line-height: normal;
}

.site-wrap {
	display: table;
	height: 100%;
	margin: 0 auto;
}

.ot_onBoardMainBgMain {
	display: table-row;
	height: 100%;
}

.ot_onBoardMainBg {
	display: table-cell;
	width: 550px;
}

.ot_footerTxt {
	color: #fff;
	text-align: center;
	height: 5vw;
	line-height: 5vw;
}

html,
body {
	height: 100%;
}

.ot_onBoardMainBg form,
.ot_BoxShadow {
	box-shadow: 5px 14px 40px rgba(112, 144, 176, 0.08);
}

.ot_BoxShadow {
	width: 100%;
}

.ot_BoxShadow img {
	width: auto !important;
}

.ot_onBoardMainBg label {
	font-size: 16px;
}

.ot_onBoardMainBg input {
	border: 1px solid rgba(229, 229, 229, 1);
	height: 50px;
	color: var(--darkColor);
}

.ot_onBoardMainBg input.ot_SubmitBtn {
	background-color: #3A52BB;
	height: 50px;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
}

.ot_onBoardMainBg h2 {
	color: var(--darkColor);
	font-size: 30px;
}

.ot_onBoardMainBg p {
	font-weight: var(--weight400);
	font-size: 16px;
	color: #415674;
}

.ot_footerTxt p {
	color: #415674;
	font-weight: var(--weight400);
}

.ot_footerTxt span {
	color: #3A52BB;
	font-weight: var(--weight600);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

input[type='date']::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

/* Home Page */
.Oat_HeaderBg {
	background-color: #3A52BB;
}

.ot_videoCardBg {
	background-color: #F6FAFF;
	border-color: rgba(229, 229, 229, 1);

}

.ot_videoCardBg input {
	background-color: rgba(112, 144, 176, 0.05);
	border-color: rgba(112, 144, 176, 0.05);
}

.ot_videoCardBg button:first-child {
	background-color: rgba(58, 82, 187, 0.2);
	color: #3A52BB;
}

.ot_videoCardBg button:last-child {
	background-color: rgba(62, 175, 63, 0.2);
	color: #34A853;
}

.ot_textBoxBg input {
	background-color: rgba(112, 144, 176, 0.05);
	border-color: rgba(112, 144, 176, 0.05);
}

@media(max-width: 767px) {
	.ot_onBoardMainBg h2 {
		font-size: 24px;
	}

	.site-wrap img {
		width: 120px;
	}

	.ot_onBoardMainBg {
		width: 100%;
	}
}


.chatbox {
	bottom: 0;
	position: fixed;
	right: 1em;
	transform: translatey(28.4em);
	transition: all 300ms ease;
	width: 380px;
}

.chatbox--is-visible {
	transform: translatey(0);
}

.chatbox__header {
	background-color: var(--taskBoxBgColor);
	border-color: rgba(229, 229, 229, 1);
	border-top-right-radius: 0.5em;
	border-top-left-radius: 0.5em;
	display: flex;
	justify-content: space-between;
	padding: 0 0.75em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.chatbox__header-cta-icon {
	color: #fff;
	margin-right: 0.75em;
}

.chatbox__header-cta-btn {
	background: none;
	border: none;
	color: #aaa;
	padding: 0.5em;
	transition: all 300ms ease;
}

.chatbox__header-cta-btn:hover {
	color: #fff;
}

.chatbox__display {
	background: var(--taskBoxBgColor);
	height: 450px;
	overflow-y: auto;
	padding: 0;
}

.list_chatbox__display {
	height: 100%;
	overflow: auto;
}

.chatbox__display-chat {
	background: #fff;
	border-radius: 0.5em;
	color: #666;
	font-weight: 300;
	font-size: 0.9rem;
	line-height: 1.5;
	padding: 0.75em;
	text-align: justify;
}

.chatbox__form {
	display: flex;
}

.chatbox__form-input {
	border: none;
	color: #222;
	font-size: 0.9rem;
	font-weight: 300;
	padding: 1.25em 1em;
	width: 100%;
}

.chatbox__form-input:required {
	box-shadow: none;
}

.chatbox__form-submit {
	background: none;
	border: none;
	color: #aaa;
	padding: 1em;
}

.active p,
.active svg path {
	color: #3A52BB;
	fill: #3A52BB;
	font-weight: 400 !important;
}

.ot_HeadDropdwon {
	background-color: rgba(255, 255, 255, 0.1);
	border-color: rgba(255, 255, 255, 0.2);
}

.close_btn {
	background: var(--blueColor);
	color: #fff;
}

.ot_textArea {
	color: #AFB5C4;
	height: 154px;
}

.modal-footer {
	padding: 15px 1.5em;
}

.ot_modalInner {
	width: 600px;
}

.ot_mdalHeader {
	padding: 30px 1.5rem 15px 1.5em;
}

.ot_mdalHeader img {
	width: 50px;
	height: 50px;
}

.ot_modalBody {
	padding: 15px 1.5em;
}

.ot_modalBody ul li a {
	background: #E5E5E5;
}

.Oat_HeaderBg img {
	width: 157.7px;
}

/* .ti_heightSchedule{
    min-height: 120px;
} */
.ti_heightSchedule .ti_heightScheduleInner button {
	height: 50px;
	width: 50px;
}

.ti_heightSchedule input {
	height: 50px;
}

/* 29-11 */
.ot_chatOnlineStatus {
	color: var(--darkColor);
	background-color: rgba(255, 255, 255, 0.8);
}

/* 29-11 */

/* .ot_activeColorRed{
    color: var(--darkColor);
    background-color: rgba(255, 255, 255, 0.8);
}
.ot_activeColorYellow{
    color: var(--darkColor);
    background-color: rgba(255, 255, 255, 0.8);
} */
.uploadImage {
	width: 130px;
	height: 130px;
}

.uploadImage img {
	width: 130px;
	height: 130px;
	object-fit: cover;
}

.box-shadowNone,
.box-shadowNone:focus,
.box-shadowNone:hover {
	box-shadow: none;
}

.ot_tblTitleWidth {
	width: 200px;
}

.ot_certificateSidebar ul {
	overflow: auto;
	word-break: break-all;
	white-space: break-spaces;
	width: 800px;
	overflow-anchor: none;
}

.ot_certificateSidebar ul li img {
	height: 137px;
}

.ot_txtSidebar li {
	margin: 0 !important;
}

.formBgShadow {
	box-shadow: 5px 14px 40px rgba(112, 144, 176, 0.08);
}

/* Chat Window */
.fixed-chatbox {
	position: fixed;
	bottom: 0;
	right: 25rem;
	width: 380px;
	background-color: var(--lightColor);
	border-radius: 8px 8px 0 0;
	display: none;
	height: 100%;
}

.chat-header {
	color: #fff;
	padding: 10px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.close-button {
	background: none;
	border: none;
	color: #fff;
	font-size: 20px;
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 10px;
}

.chat-body {
	max-height: 300px;
	overflow-y: auto;
}

.open-chat-button {
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.footerIcon {
	background-color: var(--taskBoxBgColor);
}

h1.title_head {
	display: flex;
	align-items: center;
}

h1.title_head:before,
h1.title_head:after {
	content: "";
	width: 100%;
	height: 1px;
	background: var(--borderColor);
}

h1.title_head:before {
	margin: 0 20px 0 0;
}

h1.title_head:after {
	margin: 0 0 0 20px;
}

.createAnEvent {
	background-color: var(--taskBoxBgColor);
	height: 270px;
}


.btn-lg {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}

.ti_heightScheduleInnerDateBtn {
	height: 50px;
}

.containers {
	border: 2px solid #007BFF;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	max-width: 300px;
	overflow: auto;
	/* Added overflow for auto height */
}

@media(max-width: 1023px) {
	.ot_headerDropdown {
		right: -43px !important;
		top: 27px !important;
	}

	.ti_heightScheduleInnerDateBtn,
	.ti_heightScheduleInnerEndDate {
		margin-top: 10px;
	}
}

@media(max-width: 767px) {
	.Oat_HeaderBg img {
		width: 120px;
	}

	.ot_modalInner {
		width: 100%;
	}

	.ot_modalInner svg {
		width: 35px;
	}

	.ot_headerDropdown {
		right: -43px !important;
		top: 27px !important;
	}

	.ti_heightScheduleInnerDate {
		display: block;
	}

	.ti_heightSchedule label {
		text-align: center;
	}
}

.gallery-container {
	width: 100%;
	overflow-x: scroll;
	white-space: nowrap;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.gallery-container::-webkit-scrollbar {
	display: none;
}

.gallery-container a img {
	border: 1px solid #3a52bb;
	border-radius: 50%;
	padding: 2px;
}

.ot_createGroupBox {
	background: transparent;
	border: 1px solid #d3d3d3;
	border-radius: 10px;
}

.ot_chatLeftBg {
	background-color: var(--taskBoxBgColor);
}

.ot_takePictureBg {
	/* background-color: rgb(0 0 0 / 30%);
	padding: 5px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0; */

	background-color: rgb(0 0 0 / 40%);
	padding: 5px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.ot_takePictureBg svg path {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ot_takePictureMain {
	position: relative;
	overflow: hidden;
}

.camera_blueBgColor {
	background-color: rgb(58 82 187 / 60%);
}

/* 23-11 */
.ot_sidebarMenuBg li p {
	font-weight: 300;
}

.ot_allPostReply {
	position: relative;
	padding-left: 42px;
	top: -70px;
}

.ot_leftLineBg {
	position: absolute;
	top: -30px;
	left: 30px;
	height: 100%;
	width: 2px;
	background-color: #f0f2f5;
}

/* 24-11 */
.bottomLineBG {
	padding-left: 30px;
	position: relative;
	margin-bottom: -8px;
}

.borderBefore {
	position: absolute;
	height: 100%;
	width: 2px;
	top: 40px;
	background-color: #f0f2f5;
	left: 20px;
}

.bottomBorder {
	border-bottom-style: solid;
	border-left-style: solid;
	position: absolute;
	border-bottom-width: 2px;
	width: 22px;
	border-left-width: 2px;
	height: 17px;
	border-bottom-color: #f0f2f5;
	border-right-color: #f0f2f5;
	border-left-color: #f0f2f5;
	border-bottom-left-radius: 10px;
	left: 20px;
	border-top-color: #f0f2f5;
	bottom: -50px;
}

.ot_allPostReply .cardBg {
	padding-top: 26px;
}

.ot_allPostReplyMain {
	padding-bottom: 26px;
}

.likeReplyBg {
	position: absolute;
	bottom: -23px;
	right: 0;
	z-index: 0;
	/* 29-11 */
}

section.ot_gallaryImageBg {
	width: 100%;
	height: 500px;
}

.ot_gallaryImageBg .gallery-grid {
	display: grid;
	grid-gap: 10px;
}

.ot_gallaryImageBg .gallery-grid.rows-2 {
	grid-template-columns: repeat(2, 1fr);
}

.ot_gallaryImageBg .gallery-grid.rows-3 {
	grid-template-columns: repeat(3, 1fr);
}

.ot_gallaryImageBg .gallery-grid .grid-item {
	grid-column: span 1;
	grid-row: span 1;
}

.ot_gallaryImageBg .gallery-grid .colspan-2 {
	grid-column: span 2 / auto;
}

.ot_gallaryImageBg .gallery-grid .colspan-3 {
	grid-column: span 3 / auto;
}

.ot_gallaryImageBg .gallery-grid .rowspan-2 {
	grid-row: span 2 / auto;
}

.ot_gallaryImageBg .gallery-grid .rowspan-3 {
	grid-row: span 3 / auto;
}

.ot_gallaryImageBg .gallery-grid {
	width: 100%;
	height: 500px;
	margin: 0 0 8rem;
}

.ot_gallaryImageBg .gallery-grid img {
	display: block;
	width: 100%;
	height: 100%;
	max-height: 100%;
	border-radius: 5px;
	-o-object-fit: cover;
	object-fit: cover;
}

.ot_gallaryImageBg .gallery-grid .grid-item {
	position: relative;
}

.ot_gallaryImageBg .gallery-grid .gallery-more:after {
	content: attr(data-more);
	position: absolute;
	top: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 200%;
	border-radius: 5px;
}

@media (max-width: 450px) {
	.ot_gallaryImageBg .galler-grid {
		height: 400px;
	}
}

@media (max-width: 400px) {
	.ot_gallaryImageBg .gallery-grid {
		height: 350px;
	}
}


.upload__box {
	padding: 40px;
}

.upload__inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.upload__btn {
	display: inline-block;
	font-weight: 600;
	color: #fff;
	text-align: center;
	min-width: 116px;
	padding: 5px;
	transition: all 0.3s ease;
	cursor: pointer;
	border: 2px solid;
	background-color: #4045ba;
	border-color: #4045ba;
	border-radius: 10px;
	line-height: 26px;
	font-size: 14px;
}

.upload__btn:hover {
	background-color: unset;
	color: #4045ba;
	transition: all 0.3s ease;
}

.upload__btn-box {
	margin-bottom: 10px;
}

.upload__img-wrap {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.upload__img-box {
	width: 200px;
	padding: 0 10px;
	margin-bottom: 12px;
}

.upload__img-close {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	line-height: 24px;
	z-index: 1;
	cursor: pointer;
}

.upload__img-close:after {
	content: "✖";
	font-size: 14px;
	color: white;
}

.img-bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	padding-bottom: 100%;
}

#upload-container {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

#file-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

#image-container {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	overflow: auto;
}

.image-preview {
	position: relative;
	max-width: 100px;
	overflow: hidden;
}

.image-preview img {
	width: 50px;
	height: 50px;
	border-radius: 5px;
}

.close-btn {
	position: absolute;
	top: 1px;
	right: 1px;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 1px 2px;
	border-radius: 50%;
	color: #000;
	line-height: 12px;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
}

.ot_downloadImgBtn svg {
	transform: translate(-50%, -50%);
}


/*  */
#thumbnail {
	cursor: pointer;
}

#overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(5px);
}

#overlay img {
	max-width: 80%;
	max-height: 80%;
	border: 2px solid white;
	border-radius: 5px;
}

#close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: white;
	font-size: 20px;
}

#dashboard iframe#player {
	width: 100%;
	height: 350px;
	border-radius: 5px;
}

/* 06-12 */
.ot_VideoBg {
	min-height: auto;
	max-height: 20rem;
}

.imageLightBox {
	background: rgb(0 0 0 / 35%);
}



.photos-grid-container .img-box {
	border: 1px solid #ffffff;
	position: relative;
}

.photos-grid-container .img-box:hover .transparent-box {
	background-color: rgba(0, 0, 0, 0.6);
}

.photos-grid-container .img-box:hover .caption {
	transform: translateY(-5px);
}

.photos-grid-container .caption {
	color: white;
	transition: transform 0.3s ease, opacity 0.3s ease;
	font-size: 1.5rem;
}

.photos-grid-container .transparent-box {
	/* height: 100%; */
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hide-element {
	border: 0;
	clip: rect(1px 1px 1px 1px);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}



body.modal-open {
	overflow: hidden;
	position: fixed;
	width: 100%;
}

.btn {
	margin: 20px 0;
}

.btn-danger {
	font-size: 16px;
}

.modal-backdrop.in {
	filter: alpha(opacity=80);
	opacity: .8;
}

.popup-image-gallery .modal-dialog {
	width: 100%;
}

.popup-image-gallery .modal-content {
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: transparent;
	border: 0;
	border-radius: 0;
}

.popup-slider-for {
	position: relative;
	min-height: 300px;
	z-index: 2;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.popup-slider-for:after {
	content: "\e030";
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	z-index: -1;
	color: #fff;
	opacity: .75;
}

.popup-slider-for,
.main-image {
	text-align: center;
	margin-bottom: 0;
}

.popup-slider-for .slick-slide img {
	display: inline-block;
	max-width: 100%;
	vertical-align: middle;
}

.popup-slider-nav .thumbnail-image {
	position: relative;
	margin: 0 5px;
	cursor: pointer;
}

.popup-slider-nav .thumbnail-image,
.popup-slider-nav .thumbnail-image img {
	display: block;
	width: 140px;
	height: 100px;
}

.popup-slider-nav .thumbnail-image:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.popup-slider-nav .thumbnail-image:hover:after {
	background-color: rgba(0, 0, 0, 0.4);
}

.popup-slider-nav .slick-current:after,
.popup-slider-nav .slick-current:hover:after,
.popup-slider-nav .slick-center:after,
.popup-slider-nav .slick-center:hover:after {
	background-color: transparent;
}

.pagingInfo {
	color: #fff;
	text-align: center;
	margin: 20px 0;
}

.popup-slider-for .slick-next,
.popup-slider-for .slick-prev {
	width: auto;
	height: auto;
	z-index: 1;
}

.popup-slider-for .slick-prev {
	left: 0;
}

.popup-slider-for .slick-prev:before,
.popup-slider-for .slick-next:before,
.popup-slider-for:after {
	font: normal normal 30px/1 'Glyphicons Halflings';
}

.popup-slider-for .slick-prev:before {
	content: "\e257";
}

.popup-slider-for .slick-next:before {
	content: "\e258";
}

.popup-slider-for .slick-next {
	right: 0;
}

.popup-image-gallery .close-icon {
	top: 0;
}

.slick-slide {
	outline: 0;
}

.close-btn {
	background-color: transparent;
	color: #fff;
	position: absolute;
	right: 30px;
	z-index: 3;
	font-size: 24px;
	margin: 0;
	opacity: .75;
}

.close-btn:hover,
.close-btn:focus {
	color: #fff;
	opacity: 10;
}

.popup-image-gallery .modal-dialog {
	margin: 0;
}

@media (max-width: 767px) {

	.popup-image-gallery .modal-body {
		padding: 0;
	}

	.popup-image-gallery .modal-dialog {
		margin-top: 20px;
	}

	.popup-image-gallery .close-icon {
		top: -20px;
	}

	.popup-slider-for .slick-prev {
		left: 12px;
	}

	.popup-slider-for .slick-next {
		right: 12px;
	}
}


/*  Lightbox Modal */
.slider-modal {
	display: none;
	opacity: 0;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(1) translateZ(0);
	max-width: 575px;
	width: 100%;
	z-index: 9001;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4), 0px 0px 0px 3000px rgba(0, 0, 0, 0.25);
}

.slider-modal.modal-active {
	display: block;
	-webkit-animation-name: modal-enter;
	animation-name: modal-enter;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	animation-iterations: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}

.slider-modal.modal-close {
	-webkit-animation-name: modal-leave;
	animation-name: modal-leave;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	animation-iterations: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}

.slider-modal .title {
	font-size: 16px;
	padding: 20px;
	font-weight: normal;
	color: #929292;
}

.slider-modal .slides {
	position: relative;
	overflow: hidden;
	height: 400px;
	width: 100%;
}

.slider-modal .slide {
	position: absolute;
	width: 100%;
	height: 400px;
	background-size: cover;
	background-position: center center;
	display: inline-block;
	z-index: 1;
}

.slider-modal .slide.active {
	z-index: 3;
}

.slider-modal .slider-image-caption {
	text-align: center;
	font-size: 14px;
	color: #777;
}

.slider-modal .close-icon {
	position: absolute;
	right: 10px;
	top: 8px;
	background: #fff;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	transition: all 0.2s;
	cursor: pointer;
	z-index: 9;
}

.slider-modal .close-icon:before,
.slider-modal .close-icon:after {
	position: absolute;
	content: "";
	height: 15px;
	width: 2px;
	background: #000;
	left: 50%;
	top: 50%;
	margin-top: calc(15px / -2);
	margin-left: calc(2px / -2);
}

.slider-modal .close-icon:before {
	transform: rotate(45deg);
}

.slider-modal .close-icon:after {
	transform: rotate(-45deg);
}

.slider-modal .close-icon:active {
	background: rgba(0, 0, 0, 0.1);
}

.slider-modal .nav-arrow {
	opacity: 0.8;
	position: absolute;
	background: #fff;
	height: 30px;
	width: 30px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	z-index: 10;
	top: 50%;
	margin-top: -15px;
	cursor: pointer;
	transition: all 0.2s ease-out;
}

.slider-modal .nav-arrow:hover {
	opacity: 0.8;
}

.slider-modal .nav-arrow:before,
.slider-modal .nav-arrow:after {
	content: "";
	width: 2px;
	height: 10px;
	background: #000;
	position: absolute;
	left: 9px;
	top: 10px;
}

.slider-modal .nav-arrow:before {
	transform: rotate(50deg) translateY(-50%);
}

.slider-modal .nav-arrow:after {
	transform: rotate(-50deg) translateY(50%);
}

.slider-modal .nav-arrow.right {
	right: 20px;
	transform: rotate(180deg);
}

.slider-modal .nav-arrow.left {
	left: 20px;
}

@-webkit-keyframes modal-enter {
	0% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.5);
	}

	70% {
		transform: translate(-50%, -50%) scale(1.1);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

@keyframes modal-enter {
	0% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.5);
	}

	70% {
		transform: translate(-50%, -50%) scale(1.1);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

@-webkit-keyframes modal-leave {
	0% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.8);
	}
}

@keyframes modal-leave {
	0% {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.8);
	}
}

.ot_searchBox {
	top: 54px;
}

#chatScreen {
	width: 92%;
	left: 4%;
}

.ot_pdfColor {
	color: #a0a0a0;
}

.peer:checked~.peer-checked\:bg-blue-600 {
	background-color: var(--greenBgColor);
}





/* Floating Box */

.ot_addNoteText .did-floating-label-content {
	position: relative;
	margin-bottom: 20px;
}

.ot_addNoteText .did-floating-label {
	color: #1e4c82;
	font-size: 13px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 47px;
	top: 11px;
	padding: 0 5px;
	background: #fff;
	transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	-webkit-transition: 0.2s ease all;
}

.ot_addNoteText .did-floating-input,
.did-floating-select {
	display: block;
	width: 100%;
	/* height: 43px; */
	padding: 0 47px;
	background: #fff;
	color: #323840;
	box-sizing: border-box;
}

.ot_addNoteText .did-floating-input:focus,
.did-floating-select:focus {
	outline: none;
}

.ot_addNoteText .did-floating-input:focus~.did-floating-label,
.did-floating-select:focus~.did-floating-label {
	top: -8px;
	font-size: 13px;
}

.ot_addNoteText select.did-floating-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.ot_addNoteText select.did-floating-select::-ms-expand {
	display: none;
}

.ot_addNoteText .did-floating-input:not(:placeholder-shown)~.did-floating-label {
	top: -8px;
	font-size: 13px;
}

.ot_addNoteText .did-floating-select:not([value=""]):valid~.did-floating-label {
	top: -8px;
	font-size: 13px;
}

.ot_addNoteText .did-floating-select[value=""]:focus~.did-floating-label {
	top: 11px;
	font-size: 13px;
}

.ot_addNoteText .input-group {
	display: flex;
}

.ot_addNoteText .input-group .did-floating-input {
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 0;
}

.ot_addNoteText .input-group-append {
	display: flex;
	align-items: center;
}

.ot_addNoteText .input-group-text {
	display: flex;
	align-items: center;
	font-weight: 400;
	height: 43px;
	color: #323840;
	padding: 0 5px 0 20px;
	text-align: center;
	white-space: nowrap;
	border-right: none;
}



#cancellationNotesContainer label {
	position: absolute;
	top: 0;
	left: 10px;
	padding: 0.9rem 0;
	color: rgba(0, 0, 0, 0.87);
	pointer-events: none;
	transition: all 0.15s ease;
	color: #b0b0b0;
	padding: 7px 8px;
}

#cancellationNotesContainer textarea {
	background-color: transparent;
	padding: 0.875rem 0;
	width: 100%;
	min-height: 7rem;
	resize: none;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

#cancellationNotesContainer textarea:focus~label,
#cancellationNotesContainer textarea.not-empty~label {
	font-size: 0.7rem;
	color: var(--darkColor);
	top: -1.25rem;
	transition: all 0.15s ease;
	background: white;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 0px;
}

.ot_actionBg {
	background: #ffffff;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .3s;
}

.ot_actionBg:hover,
.ot_actionBg:focus,
.ot_actionBg:active {
	background: #f2f2f2;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ot_errorPageBg {
	background-color: #f0f2fa;
}

.ot_errorPageBg p {
	color: #a1a1a1;
}

.after\:h-4:after {
	margin-left: 2px;
}

.workSpaceListItem ul li a {
	transition: .3s;
	padding: 8px;
	background-color: #fff;
	justify-content: center;
}

.workSpaceListItem ul li a:hover {
	background-color: var(--blueColor);
	color: var(--lightColor);
}

.workSpaceListItem ul {
	display: flex;
	flex-wrap: wrap;
}

.workSpaceListItem ul li {
	flex: 1 1 calc((100% / 3) - 2rem);
}



.grid--content .column--wrapper {
	display: column;
	columns: 3;
	gap: 15px;
}

.grid--content .post--item {
	margin-bottom: 15px;
}

.grid--content .post--item div {
	box-shadow: none;
	border: none;
}

.grid--content .post-user a {
	display: flex;
	align-items: center;
	text-decoration: none;
}

@media screen and (max-width: 768px) {
	.grid--content .column--wrapper {
		columns: 1;
	}
}

.patientBg_clr {
	background-color: #f4e0ff;
}

.formsBg_clr {
	background-color: #e3e0ff;
}

.doctorBg_clr {
	background-color: #e0eeff;
}

.dispenseBg_clr {
	background-color: #fce0ff;
}

.vilasBg_clr {
	background-color: #c4ffc3;
}

.arvBg_clr {
	background-color: #ffe9c3;
}

.fdaBg_clr {
	background-color: #c3f9f4;
}

.oatBg_clr {
	background-color: #e3f9c3;
}

.pharmaciestBg_clr {
	background-color: #ffdbdb;
}

.compoundBg_clr {
	background-color: #f9fdbd;
}

.blisterBg_clr {
	background-color: #cddefd;
}

.specialBg_clr {
	background-color: #f4e0ff;
}

.revenueBg_clr {
	background-color: #c4ffc3;
}

.pdf_bg .pdf_bgInner {
	background: #fafafa;
	padding: 5px 12px;
	border-radius: 5px;
}

.pdf_bg .pdf_bgInner h6 {
	font-size: 12px;
	color: #9e9e9e;
}

.pdf_bg .pdf_bgInner p {
	font-size: 16px;
}

.pdf_bg .card-body .pdf_bgMain {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	text-align: center;
}

@media(max-width: 1200px) {
	.ot_headerList {
		overflow: scroll;
		width: 100%;
		word-break: keep-all;
		white-space: nowrap;
		grid-gap: 10px;
	}
}

.box-shadow-none:focus {
	box-shadow: none !important;
}


.sidebar-container {
	position: relative;
}

.sidebar-toggleCustom {
	display: none;
}

.sidebar-label {
	position: relative;
	top: 0px;
	/* left: 10px; */
	/* padding: 10px; */
	cursor: pointer;
	font-size: 24px;
	z-index: 1;
}

.CustomSidebar {
	transition: width 0.3s ease;
	overflow: hidden;
	display: flex;
	margin-top: 10px !important;
	flex-direction: column;

}

.sidebarMenuItem {
	padding: 0px;
	display: flex;
	align-items: center;
}

.icon {
	/* font-size: 24px; */
	width: 30px;
}

.text {
	margin-left: 15px;
	transition: opacity 0.3s ease, visibility 0.3s ease;
}


.sidebar-toggleCustom:checked+label~.CustomSidebar {
	/* width: 60px; */
}

.sidebar-toggleCustom:checked+label~.CustomSidebar .text {
	/* opacity: 0;
	visibility: hidden;
	display: none; */
}
.sidebar-toggleCustom:checked+label~.CustomSidebar .btnnn  {
	/* width: 54px; */
}

/* #sidebar-toggle:checked+label {
	.CustomSidebar {
		width: 60px;
	}

}

#sidebar-toggle:checked+label{

	.CustomSidebar .text {
		opacity: 0;
		visibility: hidden;
	}
} */
.sidebarMenuItem
{

	padding-left: 0px;
}

.panel-text .add-text-item {
    background: rgba(255, 255, 255, 0.1);
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
    transition: background 0.4s;
}
.panel-text .add-text-items {
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
	color: rgba(255, 255, 255, 0.9);
	margin-bottom: 5px;
}
.panel-text .add-heading {
    font-weight: 700;
	color: rgba(255, 255, 255, 0.9);
    font-size: 1.66rem;
}

.panel-text .add-subheading {
    background: rgba(255, 255, 255, 0.1);
    font-size: 1.12rem;
    font-weight: 500;
	color: rgba(255, 255, 255, 0.9);
}
.panel-text .add-body-text {
    background: rgba(255, 255, 255, 0.1);
    font-size: 0.76rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
}
.activeSideBarCertificate {
    background: #3a52bb;
    padding: 6px;
}
.SideBarCertificate{
	padding: 6px;
}
.css-1p3m7a8-multiValue{
	background-color: hsl(0deg 0% 96.11%) !important;
	border-radius: 5px !important;
}

body {
    overflow-y: scroll !important;
}
tr.highlighted td{
	font-weight: 700 !important;
}